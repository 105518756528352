import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toasterParams = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
};

const toasterSuccess = (message) => {
  return toast.success(message, { ...toasterParams });
};

const toasterError = (message) => {
  return toast.error(message, { ...toasterParams });
};

export { toasterSuccess, toasterError };
