import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userState from "../atoms/user";

const PrivateRoute = ({ children }) => {
  const user = useRecoilValue(userState);
  const authChecker = () => {
    return sigmaAuthChecker() && sdiAuthChecker();
  };

  const sigmaAuthChecker = () => {
    return true;
  };

  const sdiAuthChecker = () => {
    return user !== null;
  };

  // console.log("user", user);

  if (!sigmaAuthChecker()) {
    return <Navigate to="/login/sigma" />;
  } else if (!sdiAuthChecker()) {
    return <Navigate to="/login/sdi" />;
  } else {
    return (
      <>
        <main className="content">{children}</main>
      </>
    );
  }
};

export default PrivateRoute;
