import { margin, style } from "@mui/system";
import FR from "../lang/fr";
import { Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import ProcessPageHeader from "./processPageHeader";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 5,
    padding: 10,
  },
  text: {
    fontSize: 10,
    fontFamily: "Helvetica",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  separator: {
    marginHorizontal: 10,
    borderBottomColor: "#444444",
    borderBottomWidth: 2,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
  bigText: {
    fontSize: 12.5,
    fontFamily: "Helvetica-Bold",
  },
  icon: {
    width: 45,
    height: 45,
  },
  logo: {
    height: "50%",
    width: "50%",
  },
  title: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    alignSelf: "center",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
});

const ProcessPage = ({
  omNumber,
  flatRate,
  hideFlatRate,
  title,
  doo,
  dont,
  cond_fin,
  logoPath,
}) => {
  return (
    <Page size="A4" styles={styles.page}>
      <ProcessPageHeader title={title} logoPath={logoPath} />

      <View style={styles.separator} />
      <View style={styles.section}>
        <View style={styles.row}>
          <Image src="./images/check.jpg" style={styles.icon} />
          <Text style={[styles.bigText, { color: "green" }]}>À FAIRE</Text>
        </View>
        <Text style={[styles.text]}>{doo}</Text>
      </View>
      <View style={styles.separator} />
      <View style={styles.section}>
        <View style={styles.row}>
          <Image src="./images/cross.jpg" style={styles.icon} />
          <Text style={[styles.bigText, { color: "red" }]}>NE PAS FAIRE</Text>
        </View>
        <Text style={[styles.text]}>{dont}</Text>
      </View>
      <View style={styles.separator} />
      <View style={styles.section}>
        <View style={styles.row}>
          <Image src="./images/euro.jpg" style={styles.icon} />
          <Text style={[styles.bigText, { color: "blue" }]}>
            CONDITIONS FINANCIÈRES
          </Text>
        </View>
        {hideFlatRate ? (
          <Text style={[styles.text]}>
            Se rapprocher de votre ordonnancement.{"\n"}
          </Text>
        ) : (
          <Text style={[styles.text]}>
            FORFAIT MAIN D'OEUVRE{" "}
            <Text style={styles.bold}>{flatRate} € HT.</Text>
            {"\n"}
            Règlement à 45 jours nets date de facture.{"\n"}
            {cond_fin}
            Facture à envoyer avec votre RIB avec la référence de l’intervention{" "}
            <Text style={styles.bold}>{omNumber}</Text> à facture@sdi81.fr{"\n"}
          </Text>
        )}
      </View>
    </Page>
  );
};

export default ProcessPage;
