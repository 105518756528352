const FR = {
  ASSURANCE_TITLE:
    "NOUVEAU PROCESS INTERVENTION ASSURANCE V.4 - STATIONS TECHNIQUES SDI 81 FRANCE",
  ASSURANCE_DO: `Contactez le client sous 24h.
          Se présenter en tant que technicien SDI 81.
          Prendre le RDV sous 7 jours.
          Informez SDI 81 par mail à ordo@sdi81.fr de la date et heure du rdv.
          Prévenir SDI 81 d'une impossibilité de prise en charge d’intervention (congés, plannings chargés, délai, ...).
          Sur site, ouvrir le produit dans tous les cas, une panne peut en cacher une autre.
          Sur site prendre une photo de la pièce avec le dégât constaté (brulures, amorces, liquides, casse) -> Vérifiez platine côté pistes et côté composants.
          Précisez en plus de votre devis l’origine du sinistre (vétusté, problème d’origine électrique extérieur, liquide, casse manifeste,
          panne interne au produit).
          Vérifier la concordance produit et date achat sur la facture que vous demandez à l’assuré.
          Appeler le dispatch SDI 81 si problème peut être résolu sur site pour validation de la prise en charge assureur.
          Etablir un devis avec le modèle complet du TV, modèle et suffixe, version, S/n , modèle, référence constructeurs des pièces et disponibilité, retournez les informations par mail au dispatch.
          Garantir la réparation 3 mois sur les pièces qui ont été remplacées.
          Pour les Interventions sur Vidéoprojecteurs, relevez le nombre d’heure au compteur lampe.`,
  ASSURANCE_DONT: `Ne jamais facturer directement l'assuré sur une intervention.
          Ne pas s’avancer avec l'assuré sur la suite que l’assureur donnera au dossier, précisez qu’en règle générale les assureurs reviendront vers eux sous 15 jours maximum.
          Ne pas enlever chez l’assuré des produits sur lesquels vous n’êtes pas missionnés.
          Ne pas passer en destruction un TV sans l'accord EXPRESS de l’assuré.
          Ne pas communiquer à l’assuré le devis de réparation.
          Ne pas entrer un TV en atelier si casse constatée sur la dalle.
       `,
  ASSURANCE_COND_FIN: `Si le devis est refusé, vous êtes réglé une fois le forfait M.O. , si le devis est accepté, vous commandez les pièces, nous informez de la date du second rdv et nous facturer toujours une fois le forfait MO et pièces.`,
  CONSTRUCTEUR_TITLE:
    "NOUVEAU PROCESS INTERVENTION CONSTRUCTEUR V.4 - STATIONS TECHNIQUES SDI 81 FRANCE",
  CONSTRUCTEUR_DO: `Contactez le client sous 24h.
       Récupérer les pièces détachées au relais Chronopost désigné par vos soins ou directement chez le consommateur, SDI 81 vous prévenant lorsque le colis est arrivé sur site ou en relais.
       Se présenter en tant que technicien SDI 81.
        Prendre le RDV sous 7 jours.
        Informez SDI 81 par mail à ordo@sdi81.fr de la date et heure du rdv.
        Prévenir SDI 81 d'une impossibilité de prise en charge d’intervention (congés, plannings chargés, délai, ...).
        Vérifier la concordance produit et date achat sur la facture.
        Constater la panne.
        Remplacer les pièces.
        Clôturer l’intervention par téléphone à l’ordonnancement au 01 89 16 48 09 ou 06 68 28 76 37.
        Garantir la réparation 3 mois sur les pièces qui ont été remplacées.
        Pour les Interventions sur Vidéoprojecteurs, relevez le nombre d’heure au compteur lampe.
        `,
  CONSTRUCTEUR_DONT: `Ne jamais facturer directement le consommateur sur une intervention.
        Ne pas enlever un produit sur lesquels vous n’êtes pas missionnés.
        Ne pas passer en destruction un TV sans l'accord de SDI 81.
        Ne pas entrer un TV en atelier si casse constatée sur la dalle.`,
  CONSTRUCTEUR_COND_FIN: ``,
  INTERVENTION_TITLE: `NOUVEAU PROCESS INTERVENTION GÉNÉRIQUE V.4 - STATIONS TECHNIQUES SDI 81 FRANCE`,
  INTERVENTION_DO: `Contactez le client sous 24h.
       Se présenter en tant que technicien SDI 81.
        Prendre le RDV.
        Informez SDI 81 par mail à ordo@sdi81.fr de la date et heure du rdv.
        Contacter SDI 81 sur site pour support technique au 01 89 16 48 09 ou 06 68 28 76 37.
        `,
  INTERVENTION_DONT: `Ne jamais facturer directement le client sur une intervention.
        Ne pas s’avancer sur la suite du dossier.
        Ne pas enlever des produits sur lesquels vous n'êtes pas missionés.`,
  INTERVENTION_COND_FIN: ``,
  GARANTIE_TITLE: `NOUVEAU PROCESS INTERVENTION GARANTIE ÉTENDUE V.4 - STATIONS TECHNIQUES SDI 81 FRANCE`,
  GARANTIE_DO: `Contactez le client sous 24h.
          Se présenter en tant que technicien SDI 81.
          Prendre le RDV sous 7 jours.
          Informez SDI 81 par mail à ordo@sdi81.fr de la date et heure du rdv.
          Prévenir SDI 81 d'une impossibilité de prise en charge d’intervention (congés, plannings chargés, délai, ...).
          Sur site prendre une photo de la pièce avec le dégât constaté (brulures, amorces, liquides, casse) -> Vérifiez platine côté pistes et côté composants.
          Précisez en plus de votre devis l’origine du sinistre (vétusté, problème d’origine électrique extérieur, liquide, casse manifeste, panne interne au produit).
          Vérifier la concordance produit et date achat sur la facture que vous demandez à l’assuré.
          Etablir un devis avec le modèle complet du TV, modèle et suffixe, version, S/n , modèle, référence constructeurs des pièces et disponibilité, retournez les informations par mail au dispatch.
          Garantir la réparation 3 mois sur les pièces qui ont été remplacées.
          Pour les Interventions sur Vidéoprojecteurs, relevez le nombre d’heure au compteur lampe.`,
  GARANTIE_DONT: `Ne jamais facturer directement l'assuré sur une intervention.
          Ne pas s’avancer avec l'assuré sur la suite que l’assureur donnera au dossier, précisez qu’en règle générale les assureurs reviendront vers eux sous 15 jours maximum.
          Ne pas enlever des produits sur lesquels vous n’êtes pas missionnés.
          Ne pas passer en destruction un TV sans l'accord EXPRESS de l’assuré.
          Ne pas communiquer à l’assuré le devis de réparation.
          Ne pas entrer un TV en atelier si casse constatée sur la dalle.
       `,
  GARANTIE_COND_FIN: `Si le devis est refusé, vous êtes réglé une fois le forfait M.O. , si le devis est accepté, vous commandez les pièces, nous informez de la date du second rdv et nous facturer toujours une fois le forfait MO et pièces.`,
};

export default FR;
