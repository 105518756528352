import React from "react";
import { Field } from "formik";
import { FormControlLabel, List, Radio } from "@mui/material";
import styles from "./radio.module.css";
import { RadioGroup } from "formik-mui";

const RadioField = ({ className, name, options, onChange, ...props }) => {
  return (
    <Field component={RadioGroup} name={name} className={className}>
      <List orientation="horizontal">
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio disabled={props.isSubmitting} />}
            disabled={props.isSubmitting}
            label={option.label}
            onChange={onChange}
          />
        ))}
      </List>
    </Field>
  );
};

export default RadioField;
