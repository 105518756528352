import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Field, Form, Formik, useField } from "formik";
import { array, number, object, string } from "yup";
import React, { useCallback, useEffect } from "react";
import styles from "./FormOm.module.css";
import RadioField from "../../component/Radio/radio";
import { formToModel } from "../../helpers/mapper";
import { pdf } from "@react-pdf/renderer";
import MyDocument from "../../component/mydocument";
import { useRecoilValue } from "recoil";
import userState from "../../atoms/user";
import SdiService from "../../services/sdi-service";
import { useLocation, useNavigate } from "react-router-dom";
import getOmNumbers from "../../helpers/om-numbers";
import { useDropzone } from "react-dropzone";
import { ArrowBack, DeleteForever } from "@mui/icons-material";
import logoPath from "../../helpers/logo-path";

import {
  toasterError,
  toasterSuccess,
} from "../../component/toast/toasterMailer";
import SigmaService from "../../services/sigma-service";

const initialValues = {
  omNumber: "",
  omType: "",
  diagnostic: "",
  flatRate: 0,
  hideFlatRate: false,
  technicianEmail: "",
  comment: "",
  name: "",
  adress: "",
  postalCode: "",
  city: "",
  country: "",
  tel1: "",
  tel2: "",
  brand: "",
  model: "",
  serialNumber: "",
  purchaseDate: "",
};

const validationSchema = object({
  omNumber: string().required("Numéro d'OM requis"),
  omType: string().required("Type d'OM requis"),
  diagnostic: string().optional(),
  flatRate: number().required("Prix forfaitaire requis").min(0),
  technicianEmail: string().email().required("Email du technicien requis"),
  comment: string().optional(),
  name: string().required("Nom du client requis"),
  adress: string().required("Adresse du client requis"),
  postalCode: string().required("Code postal du client requis"),
  city: string().required("Ville du client requis"),
  country: string().optional(),
  tel1: string().optional(),
  tel2: string().optional(),
  brand: string().optional(),
  model: string().optional(),
  serialNumber: string().optional(),
  purchaseDate: string().optional(),
});

const omTypes = [
  { label: "Assurance", value: "Assurance" },
  { label: "Constructeur", value: "Constructeur" },
  { label: "Garantie Étendue", value: "Garantie" },
  { label: "Générique", value: "Générique" },
];

const FormOm = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate(-1);
  const [autoOmNumber, setAutoOmNumber] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [logo, setLogo] = React.useState("");
  const woOriginal = "";
  const token = localStorage.getItem("token");
  const { state } = useLocation();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((curr) => [...curr, ...acceptedFiles]);
  }, []);

  useEffect(() => {
    // console.log("files", files);
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ["image/*", "video/*", ".pdf"],
    maxSize: 1024 * 1024 * 10, // 10MB
  });

  const removeFile = (file) => {
    setFiles((curr) => curr.filter((f) => f.name !== file));
  };

  const handleSubmit = async (values, actions) => {
    const formData = new FormData();

    // console.log("values", values);
    // console.log("om", formToModel(values));

    const om = formToModel(values);
    const procedure = await pdf(MyDocument(om)).toBlob();
    const procedureBlob = new Blob([procedure], { type: "application/pdf" });
    procedureBlob.name = `${om.omNumber}.pdf`;
    formData.append(
      "attachments",
      procedureBlob,
      `${om.omNumber} ${om.client.city}.pdf`
    );
    formData.append("omStringified", JSON.stringify(om));
    formData.append("basicAuthStringified", JSON.stringify(user));

    files.forEach((file) => {
      formData.append("attachments", file);
    });

    SdiService.envoyerOm(formData)
      .then((res) => {
        actions.setSubmitting(false);
        if (values?.wo) {
          SigmaService.postWorkOrderAcknowledgement(token, values?.wo).then(
            (res) => {
              if (!res?.success) {
                toasterError("Erreur de communication avec Sigma");
              } else {
                console.log("Sigma acknowledgement response : ", res?.success);
              }
            }
          );
        }
        toasterSuccess("Mail envoyé");
        navigate(-1);
      })
      .catch((err) => {
        console.error("err", err);
        actions.setSubmitting(false);
        toasterError("Erreur lors de l'envoi du mail");
      });
  };

  const handleRadioChange = (omType, setFieldValue) => {
    setLogo(logoPath[omType]);
    SdiService.getCompteurValue(omType, user)
      .then((ctr) => {
        let omNumber = getOmNumbers(omType, ctr);
        setFieldValue("omNumber", omNumber);
        navigator.clipboard.writeText(omNumber);
        toasterSuccess("Numéro d'OM copié dans le presse papier");
      })
      .catch((err) => {
        toasterError("Erreur lors de la récupération du compteur");
        console.error("err", err);
      });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={`${styles.row}`}>
            <div className={`${styles.expanded} ${styles.end}`}>
              <img className={styles.logoHeader} src={"/images/logo.jpg"}></img>
            </div>
            <div className={`${styles.expanded} ${styles.start}`}>
              <img
                className={styles.logoHeader}
                src={"/images/calldispatchlogosdi81.jpg"}></img>
            </div>
          </div>
          <div className={`${styles.row} ${styles.spaceBetween}`}>
            <h1 className={styles.h1}>
              Création d'un ordre de mission :{" "}
              {state ? state?.wo : "Formulaire Libre"}
            </h1>

            <IconButton
              className={styles.disconnect}
              variant="contained"
              color="error"
              aria-label="disconnect"
              onClick={() => {
                navigate(-1);
              }}>
              <ArrowBack />
            </IconButton>
          </div>
          <Formik
            initialValues={{ ...initialValues, ...state }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}>
            {({
              values,
              errors,
              isSubmitting,
              isValidating,
              setFieldValue,
            }) => (
              <Form className={styles.Form}>
                <div
                  className={`${styles.row} ${styles.justifyStart} ${styles.gap16}`}>
                  <Field
                    name="omNumber"
                    type="text"
                    label="Référence Interne"
                    as={TextField}
                    disabled={autoOmNumber}
                  />
                  <Tooltip title="Si désactivé, le numéro d'OM est automatiquement créé par le service">
                    <label className={styles.centered}>
                      <Field
                        name="omAuto"
                        type="checkbox"
                        label="Numéro d'OM automatique"
                        as={Checkbox}
                        onChange={(e) => {
                          setAutoOmNumber(!e.target.checked);
                          setFieldValue("omAuto", e.target.checked);
                          if (!e.target.checked) {
                            setFieldValue(
                              "omNumber",
                              handleRadioChange(values.omType, setFieldValue)
                            );
                          }
                        }}
                      />
                      <span className={styles.italic}>Mode Manuel</span>
                    </label>
                  </Tooltip>

                  <RadioField
                    className={styles.radioFlexFlow}
                    name="omType"
                    options={omTypes}
                    onChange={(e) => {
                      !values.omAuto
                        ? handleRadioChange(e.target.value, setFieldValue)
                        : setLogo(logoPath[e.target.value]);
                    }}></RadioField>
                  {logo !== "" && (
                    <img src={`./images/${logo}`} className={styles.logoType} />
                  )}
                </div>
                <div className={`${styles.row} ${styles.mb}`}>
                  <div>
                    <h2>Client</h2>
                    <Field
                      fullWidth
                      name="name"
                      type="text"
                      label="Nom"
                      as={TextField}
                    />
                    <Field
                      fullWidth
                      name="adress"
                      type="text"
                      label="Adresse"
                      as={TextField}
                    />
                    <Field
                      fullWidth
                      name="postalCode"
                      type="text"
                      label="Code postal"
                      as={TextField}
                    />
                    <Field
                      fullWidth
                      name="city"
                      type="text"
                      label="Ville"
                      as={TextField}
                    />
                    <div className={`${styles.row} ${styles.wrapTel}`}>
                      <Field
                        fullWidth
                        name="tel1"
                        type="text"
                        label="Tel 1"
                        as={TextField}
                      />
                      <Field
                        fullWidth
                        name="tel2"
                        type="text"
                        label="Tel 2"
                        as={TextField}
                      />
                    </div>
                  </div>
                  <div>
                    <h2>Appareil</h2>
                    <Field
                      fullWidth
                      name="brand"
                      type="text"
                      label="Marque"
                      as={TextField}
                    />
                    <Field
                      fullWidth
                      name="model"
                      type="text"
                      label="Modèle"
                      as={TextField}
                    />
                    <Field
                      fullWidth
                      name="serialNumber"
                      type="text"
                      label="S/N"
                      as={TextField}
                    />
                    <Field
                      fullWidth
                      name="purchaseDate"
                      type="text"
                      label="Date d'achat"
                      as={TextField}
                    />
                  </div>
                </div>
                <div className={styles.mb}>
                  <h2 className={styles.mt0}>Autres informations</h2>
                  <div>
                    <Field
                      fullWidth
                      name="diagnostic"
                      type="text"
                      label="Diagnostic"
                      as={TextField}
                    />
                  </div>
                  <div>
                    <Field
                      fullWidth
                      name="comment"
                      multiline
                      rows={3}
                      type="text"
                      label="Commentaire SDI 81"
                      as={TextField}
                    />
                  </div>
                  <div className={`${styles.row} ${styles.justifyStart}`}>
                    <Field
                      className={styles.flexGrow1}
                      name="technicianEmail"
                      type="email"
                      //  add props

                      label="Email du technicien"
                      as={TextField}
                    />
                    <Field
                      className={styles.flexGrow1}
                      name="flatRate"
                      type="number"
                      step="5"
                      label="Forfait main d'oeuvre"
                      InputProps={{ endAdornment: `€\u00A0HT` }}
                      inputProps={{ min: 0, step: 5 }}
                      as={TextField}
                    />
                    <Tooltip title="Si coché, le forfait ne sera pas ecrit sur l'ordre de mission généré au format PDF">
                      <label className={`${styles.centered} ${styles.mb24}`}>
                        <Field
                          name="hideFlatRate"
                          type="checkbox"
                          label="Masquage du forfait"
                          as={Checkbox}
                          onChange={(e) => {
                            setFieldValue("hideFlatRate", e.target.checked);
                          }}
                        />
                        Masquer le Forfait ?
                      </label>
                    </Tooltip>
                  </div>
                </div>
                <div className={styles.mb}>
                  <h2 className={styles.mt0}>Documents à joindre</h2>
                  <div {...getRootProps()} className={styles.dropZone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Déposez les fichiers ici...</p>
                    ) : (
                      <p>
                        Glissez et déposez les fichiers ici, ou cliquez pour
                        sélectionner des fichiers. (10 mo maximum)
                      </p>
                    )}
                  </div>
                  {
                    <ul>
                      {files.map((file) => (
                        <li key={file.name}>
                          {file.name} - {Math.round(file.size / 1024)} ko
                          <IconButton
                            className={styles.disconnect}
                            variant="contained"
                            color="error"
                            aria-label="disconnect"
                            onClick={() => removeFile(file.name)}>
                            <DeleteForever />
                          </IconButton>
                        </li>
                      ))}
                      {files.length === 0 && (
                        <li>Aucune pièce jointe additionnelle</li>
                      )}
                    </ul>
                  }
                </div>
                <div className={`${styles.expanded} ${styles.end}`}>
                  <Button
                    className={styles.mt12}
                    type="submit"
                    disabled={
                      isSubmitting || isValidating || Object.keys(errors).length
                    }
                    color="info">
                    Envoyer
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles.footer}></div>
    </>
  );
};

export default FormOm;
