const formToModel = (values) => {
  let client = {};
  client.name = values.name;
  client.adress = values.adress;
  client.postalCode = values.postalCode;
  client.city = values.city;
  client.country = values.country;
  client.phoneNumbers = [];
  values?.tel1 && client.phoneNumbers.push(values.tel1);
  values?.tel2 && client.phoneNumbers.push(values.tel2);
  values?.tel3 && client.phoneNumbers.push(values.tel3);

  let appareil = {};
  appareil.brand = values.brand;
  appareil.model = values.model;
  appareil.serialNumber = values.serialNumber;
  appareil.purchaseDate = values.purchaseDate;

  let om = {};
  om.client = client;
  om.appareil = appareil;
  om.omNumber = values.omNumber;
  om.omType = values.omType;
  om.diagnostic = values.diagnostic;
  om.flatRate = values.flatRate;
  om.technicianEmail = values.technicianEmail;
  om.comment = values.comment;
  om.hideFlatRate = values.hideFlatRate;

  return om;
};

const sigmaToForm = (wo) => {
  let form = {};
  form.wo = wo.id ?? "";
  form.omType = "";
  form.diagnostic = wo.claim_description ?? "";
  form.flatRate = 0;
  form.technicianEmail = "";
  form.hideFlatRate = false;
  form.comment = "";
  form.name = wo.customer?.first_name + " " + wo.customer?.last_name ?? "";
  form.adress = wo.customer?.address ?? "";
  form.postalCode = wo.customer?.city_postal_code ?? "";
  form.city = wo.customer?.city_name ?? "";
  form.country = wo.customer?.country_code ?? "";
  form.tel1 = wo.customer?.phone ?? "";
  form.tel2 = "";
  form.brand = wo.device?.brand_name ?? "";
  form.model = wo.device?.model_commercial_name ?? "";
  form.serialNumber = wo.device?.serial_number ?? "";
  form.purchaseDate = wo.device?.purchase_date ?? "";

  return form;
};

export { formToModel, sigmaToForm };
