import axios from "../helpers/axios-config";

export default class SigmaService {
  static login(auth) {
    return axios.post("/sigma/login", { ...auth }).then((response) => {
      return response.data;
    });
  }

  static getWorkOrders(token) {
    return axios.post("/sigma/wo", token).then((response) => {
      return response.data;
    });
  }

  static getWorkOrder(token, id) {
    return axios.post("/sigma/wo/" + id, token).then((response) => {
      return response.data;
    });
  }

  static postWorkOrderAcknowledgement(token, id) {
    return axios
      .post("/sigma/wo/" + id + "/acknowledge", token)
      .then((response) => {
        return response.data;
      });
  }
}
