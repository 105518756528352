import {
  BlobProvider,
  Document,
  Page,
  pdf,
  PDFViewer,
  StyleSheet,
} from "@react-pdf/renderer";
import React, { useState } from "react";
import MyDocument from "./mydocument";
import ProcessPageHeader from "./processPageHeader";

const DownloadPdf = () => {
  const [download, setDownload] = useState(false);

  const handleDownload = async () => {
    setDownload(true);
  };

  const handlePost = async () => {
    const procedure = await pdf(MyDocument()).toString();
    const formData = new FormData();
    formData.append("attachment", procedure);
    const response = await fetch("http://localhost:8080/mailpdf", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    // console.log(data);
  };
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#E4E4E4",
    },
  });

  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page size="A4" styles={styles.page}>
          <ProcessPageHeader
            title="TEST"
            logoPath="generique2.png"></ProcessPageHeader>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default DownloadPdf;
