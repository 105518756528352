import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import ProcessPage from "./processPage";
import RapportInterPage from "./rapportInterPage";
import FR from "../lang/fr";
import logos from "../helpers/logo-path";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const MyDocument = (om) => {
  const getTextByOmType = (omType) => {
    let title = "";
    let doo = "";
    let dont = "";
    let cond_fin = "";
    let logoPath = "";
    switch (omType) {
      case "Assurance":
        title = FR.ASSURANCE_TITLE;
        doo = FR.ASSURANCE_DO;
        dont = FR.ASSURANCE_DONT;
        cond_fin = FR.ASSURANCE_COND_FIN;
        logoPath = logos.Assurance;
        break;
      case "Constructeur":
        title = FR.CONSTRUCTEUR_TITLE;
        doo = FR.CONSTRUCTEUR_DO;
        dont = FR.CONSTRUCTEUR_DONT;
        cond_fin = FR.CONSTRUCTEUR_COND_FIN;
        logoPath = logos.Constructeur;
        break;
      case "Garantie":
        title = FR.GARANTIE_TITLE;
        doo = FR.GARANTIE_DO;
        dont = FR.GARANTIE_DONT;
        cond_fin = FR.GARANTIE_COND_FIN;
        logoPath = logos.Garantie;
        break;
      case "Générique":
        title = FR.INTERVENTION_TITLE;
        doo = FR.INTERVENTION_DO;
        dont = FR.INTERVENTION_DONT;
        cond_fin = FR.INTERVENTION_COND_FIN;
        logoPath = logos.Générique;
        break;
      default:
        break;
    }
    // console.log("logoPath: ", logoPath);
    return { title, doo, dont, cond_fin, logoPath };
  };

  const omType = om.omType;
  const { title, doo, dont, cond_fin, logoPath } = getTextByOmType(omType);
  return (
    <Document>
      <ProcessPage
        omNumber={om.omNumber}
        flatRate={om.flatRate}
        hideFlatRate={om.hideFlatRate}
        title={title}
        doo={doo}
        dont={dont}
        cond_fin={cond_fin}
        logoPath={logoPath}
      />
      <RapportInterPage om={om} title={title} logoPath={logoPath} />
    </Document>
  );
};

export default MyDocument;
