import { margin, style } from "@mui/system";
import { Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import ProcessPageHeader from "./processPageHeader";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 5,
    padding: 10,
  },
  text: {
    fontSize: 10,
    fontFamily: "Helvetica",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  separator: {
    marginHorizontal: 10,
    borderBottomColor: "#444444",
    borderBottomWidth: 2,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
  bigText: {
    fontSize: 12.5,
    fontFamily: "Helvetica-Bold",
  },
  smallerText: {
    fontSize: 8,
    fontFamily: "Helvetica",
    lineHeight: 1.5,
    paddingTop: 5,
  },
  icon: {
    width: 45,
    height: 45,
  },
  logo: {
    height: "50%",
    width: "50%",
  },
  title: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
    alignSelf: "center",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  container: {
    padding: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    flexGrow: 1,
    marginBottom: "16px",
  },
  item: {},
  mediumBox: {
    width: "70px",
  },
  largeBox: {
    width: "160px",
  },
  thead: {
    backgroundColor: "rgb(233,239,247)",
    height: "25px",
    justifyContent: "center",
    borderBottom: "1px solid #000",
    borderStyle: "inset",
  },
  theadLarger: {
    height: "40px",
  },
  theadText: {
    textAlign: "center",
    alignSelf: "center",
  },
  trow: {
    backgroundColor: "#fff",
    flexGrow: 1,
    height: "50px",
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
  },
  smallRow: {
    height: "25px",
    borderBottom: "1px solid #000",
  },
  leftRow: {
    textAlign: "start",
    paddingLeft: "10px",
    justifyContent: "center",
  },

  col: {
    flexGrow: 1,
    width: "100%",
    borderLeft: "1px solid #000",
  },
  smallText: {
    fontSize: 10,
    fontFamily: "Helvetica-Oblique",
    lineHeight: 1.5,
    color: "#888888",
    paddingTop: 12,
  },
  checkbox: {
    width: "14px",
    height: "14px",
    border: "1.5px solid #999",
    borderRadius: "3px",
    marginLeft: "6px",
    backgroundColor: "#eee",
  },
});

const RapportInterPage = ({ om, title, logoPath }) => {
  // console.log("diagnostic", om?.diagnostic);
  return (
    <Page size="A4" styles={styles.page}>
      <ProcessPageHeader title={title} logoPath={logoPath} />
      <View style={styles.separator} />
      <View style={styles.container}>
        <View style={styles.item}>
          <View style={styles.row}>
            <Text style={[styles.text, styles.mediumBox]}>OM :</Text>
            <Text style={[styles.text, styles.bold]}>
              {om?.omNumber.toUpperCase()}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.text, styles.mediumBox]}>CLIENT :</Text>
            <Text style={[styles.text, styles.bold]}>
              {om?.client?.name.toUpperCase()}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.text, styles.mediumBox]}>ADRESSE :</Text>
            <Text style={[styles.text, styles.bold]}>
              {om?.client?.adress.toUpperCase()}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.text, styles.mediumBox]}>CP :</Text>
            <Text style={[styles.text, styles.bold]}>
              {om?.client?.postalCode.toUpperCase()}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.text, styles.mediumBox]}>VILLE :</Text>
            <Text style={[styles.text, styles.bold]}>
              {om?.client?.city.toUpperCase()}
            </Text>
          </View>
          {om?.client?.phoneNumbers &&
            om?.client?.phoneNumbers.map((phone, index) => (
              <View style={[styles.row, styles.largeBox]}>
                <Text style={[styles.text, styles.mediumBox]}>
                  Tél {index + 1} :{"\u00A0"}
                </Text>
                <Text style={[styles.text, styles.bold]}>{phone}</Text>
              </View>
            ))}
        </View>
        <View style={[styles.item]}>
          <View style={[{ border: "1px solid #000" }]}>
            <View style={styles.row}>
              <View style={[styles.col, { borderLeft: "0" }]}>
                <View style={[styles.thead, styles.theadLarger]}>
                  <Text style={[styles.text, styles.theadText]}>
                    Type, Marque, Modèle
                  </Text>
                </View>
                <View style={styles.trow}>
                  <Text style={[styles.text, styles.bold]}>
                    {om?.appareil?.brand} {om?.appareil?.model}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={[styles.thead, styles.theadLarger]}>
                  <Text style={[styles.text, styles.theadText]}>
                    Suffixe Modèle{"\n"}(à remplir)
                  </Text>
                </View>
                <View style={styles.trow}>
                  <Text style={[styles.text, styles.bold]}></Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={[styles.thead, styles.theadLarger]}>
                  <Text style={[styles.text, styles.theadText]}>
                    Version{"\n"}(pour Samsung)
                  </Text>
                </View>
                <View style={styles.trow}>
                  <Text style={[styles.text, styles.bold]}></Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={[styles.thead, styles.theadLarger]}>
                  <Text style={[styles.text, styles.theadText]}>
                    Date Achat{"\n"}(à vérifier)
                  </Text>
                </View>
                <View style={styles.trow}>
                  <Text style={[styles.text, styles.bold]}>
                    {om?.appareil?.purchaseDate}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={[styles.thead, styles.theadLarger]}>
                  <Text style={[styles.text, styles.theadText]}>S/N</Text>
                </View>
                <View style={styles.trow}>
                  <Text style={[styles.text, styles.bold]}>
                    {om?.appareil?.serialNumber}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.smallText}>Cochez les cases nécessaires :</Text>
            <View style={styles.row}>
              <Text style={[styles.largeBox, styles.text, styles.bold]}>
                Traces visibles :
              </Text>
              <View style={[styles.largeBox, styles.row]}>
                <Text style={[styles.text]}>Amorces ou Brûlures</Text>
                <View style={styles.checkbox}></View>
              </View>
              <View style={[styles.mediumBox, styles.row, { width: "100px" }]}>
                <Text style={[styles.text]}>Liquide</Text>
                <View style={styles.checkbox}></View>
              </View>
              <View style={[styles.mediumBox, styles.row]}>
                <Text style={[styles.text]}>Casse</Text>
                <View style={styles.checkbox}></View>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={[styles.largeBox, styles.text, styles.bold]}>
                Produit rentré en atelier :
              </Text>
              <View style={[styles.largeBox, styles.row]}>
                <View style={styles.row}>
                  <Text style={[styles.text]}>Oui</Text>
                  <View style={styles.checkbox}></View>
                </View>
                <View style={[styles.row, { paddingLeft: 39.7 }]}>
                  <Text style={[styles.text]}>Non</Text>
                  <View style={styles.checkbox}></View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={[{ border: "1px solid #000" }]}>
            <View style={styles.row}>
              <View style={[styles.col, { borderLeft: "0" }]}>
                <View style={styles.thead}>
                  <Text style={[styles.text, styles.theadText]}>
                    SYMPTOME - DESCRIPTION DE LA PANNE CONSTATEE
                  </Text>
                </View>
                <View style={[styles.trow, styles.smallRow, styles.leftRow]}>
                  <Text style={[styles.smallerText]}>{om?.diagnostic}</Text>
                </View>
                <View
                  style={[
                    styles.trow,
                    styles.smallRow,
                    { borderBottom: "0" },
                  ]}></View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={[{ border: "1px solid #000" }]}>
            <View style={styles.row}>
              <View style={[styles.col, { borderLeft: "0" }]}>
                <View style={styles.thead}>
                  <Text style={[styles.text, styles.theadText]}>
                    INTERVENTION EFFECTUEE PAR LE TECHNICIEN
                  </Text>
                </View>
                <View style={[styles.trow, styles.smallRow]}></View>
                <View style={[styles.trow, styles.smallRow]}></View>
                <View style={[styles.trow, styles.smallRow]}></View>
                <View
                  style={[
                    styles.trow,
                    styles.smallRow,
                    { borderBottom: "0" },
                  ]}></View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.item}>
          <View style={[{ border: "1px solid #000" }]}>
            <View style={styles.row}>
              <View style={[styles.col, { borderLeft: "0" }]}>
                <View style={styles.thead}>
                  <Text style={[styles.text, styles.theadText]}>SIGNATURE</Text>
                </View>
                <View style={[styles.row, { alignContent: "start" }]}>
                  <View
                    style={[
                      styles.trow,
                      {
                        height: "120px",
                        textAlign: "start",
                        alignSelf: "start",
                        justifyContent: "start",
                        padding: "4px",
                      },
                    ]}>
                    <Text style={[styles.text, styles.bold]}>
                      Technicien SDI 81
                    </Text>
                    <Text style={[styles.text]}>
                      Nom : __________________________________
                    </Text>
                    <Text style={[styles.text]}>
                      Date :{"\u00A0".repeat(16)}/{"\u00A0".repeat(16)}/
                    </Text>
                    <Text style={[styles.text]}>Signature :</Text>
                  </View>
                  <View
                    style={[
                      styles.trow,
                      {
                        height: "120px",
                        textAlign: "start",
                        alignSelf: "start",
                        justifyContent: "start",
                        padding: "4px",
                        borderLeft: "1px solid #000",
                      },
                    ]}>
                    <Text style={[styles.text, styles.bold]}>
                      Signataire pour le client
                    </Text>
                    <Text style={[styles.text]}>
                      Nom : __________________________________
                    </Text>
                    <Text style={[styles.text]}>
                      Date :{"\u00A0".repeat(16)}/{"\u00A0".repeat(16)}/
                    </Text>
                    <Text style={[styles.text]}>Signature :</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default RapportInterPage;
