const getOmNumbers = (omType, ctr) => {
  const OmTags = {
    Assurance: "ASS",
    Garantie: "EXT",
    Constructeur: "CONS",
    Générique: "GEN",
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();

  //format ctr with 3 digits
  const ctrStr = ctr.toString().padStart(3, "0");

  //format month with 2 digits
  const monthStr = month.toString().padStart(2, "0");

  //format day with 2 digits
  const dayStr = day.toString().padStart(2, "0");

  //format year with 2 last digits
  const yearStr = year.toString().slice(2);

  const omNumber = `${yearStr}${monthStr}${dayStr}${OmTags[omType]}${ctrStr}`;

  return omNumber;
};

export default getOmNumbers;
