import { Button, Grid, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import React from "react";
import styles from "./SigmaLogin.module.css";
import SigmaService from "../../services/sigma-service";
import { useRecoilState } from "recoil";
import sigmaState from "../../atoms/sigma";
import { useNavigate } from "react-router-dom";
import userState from "../../atoms/user";

const initialValues = {
  username: "",
  password: "",
};

const validationSchema = object({
  username: string().required("Required"),
  password: string().required("Required"),
});

const SigmaLogin = () => {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");

  const handleSubmit = (values, actions) => {
    setMessage("👉 Tentative de connexion en cours ...");
    SigmaService.login({
      username: values.username,
      password: values.password,
    })
      .then((response) => {
        if (response?.success) {
          localStorage.setItem("token", response.access_token);
        }
        if (!response?.success) {
          setMessage("🔐 Identifiant ou mot de passe incorrect.");
          return;
        }
        actions.setSubmitting(false);

        navigate("/login/sdi");
      })
      .catch((err) => {
        console.error(err);
        setMessage(
          "🔥 Connexion impossible. Verifiez vos identifiants ou contactez un administrateur."
        );
        actions.setSubmitting(false);
      });
  };

  return (
    <div className={styles.container}>
      <img src="/images/logo.jpg" alt="SDI logo" className={styles.logo} />
      <div className={styles.card}>
        <h1 className={styles.h1}>
          Connexion à{" "}
          <span className={`${styles.h1} ${styles.orange}`}>Sigma</span>
        </h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions);
          }}>
          {({ values, errors, isSubmitting, isValidating }) => (
            <Form className={styles.Form}>
              <div>
                <Field
                  fullWidth
                  name="username"
                  type="text"
                  label="Identifiant Sigma"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  as={TextField}
                />
              </div>
              <div>
                <Field
                  fullWidth
                  name="password"
                  type="password"
                  label="Mot de passe"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  as={TextField}
                />
              </div>
              {message !== "" && (
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {message}
                  </Typography>
                </Grid>
              )}
              <Button
                type="submit"
                disabled={
                  isSubmitting || isValidating || Object.keys(errors).length
                }
                color="info">
                Continuer
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <img
        src="/images/calldispatchlogosdi81.jpg"
        alt="SDI logo"
        className={`${styles.logo} ${styles.calldispatchLogo}`}
      />
    </div>
  );
};

export default SigmaLogin;
