import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import SigmaLogin from "./pages/SigmaLogin/SigmaLogin";
import SdiLogin from "./pages/SdiLogin/SdiLogin";
import SearchPage from "./pages/SearchPage/SearchPage";
import FormOm from "./pages/FormOm/FormOm";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "./component/mydocument";
import Pr from "./routers/PrivateRoute";
import ProcessPageHeader from "./component/processPageHeader";
import DownloadPdf from "./component/downloadPdf";

function App() {
  // prettier-ignore
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Pr><SearchPage /></Pr>} />
        <Route path="/login/sdi" element={<SdiLogin />} />
        <Route path="/login/sigma" element={<SigmaLogin />} />
        <Route path="/search" element={<Pr><SearchPage /></Pr>} />
        <Route path="/form" element={<Pr><FormOm /></Pr>} />
        <Route
          path="/pdf"
          element={
            <DownloadPdf/>
          }
        />
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </div>
  );
}

export default App;
