import axios from "../helpers/axios-config";

export default class SdiService {
  static login(auth) {
    return axios
      .post("/authenticate", { ...auth })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static test() {
    return axios
      .get("/test")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static envoyerOm(om) {
    return axios
      .post("/mailpdf", om, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static getCompteurValue(type, auth) {
    return axios
      .get("/compteur/" + type, {
        params: {
          username: auth.username,
          password: auth.password,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
