import { IconButton, Button, TextField, Autocomplete } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import React, { useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./SearchPage.module.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import userState from "../../atoms/user";
import SigmaService from "../../services/sigma-service";
import { toasterError } from "../../component/toast/toasterMailer";
import { sigmaToForm } from "../../helpers/mapper";

const initialValues = {
  wo: "",
};

const validationSchema = object({
  wo: string(),
});

const SearchPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const token = localStorage.getItem("token");
  const [wos, setWos] = React.useState([]);

  useEffect(() => {
    // console.log("wos", wos);
  }, [wos]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      if (!token) {
        navigate("/login/sigma");
      }
      SigmaService.getWorkOrders(token)
        .then((response) => {
          if (response?.success) {
            setWos(response.data.map((wo) => wo.id));
          } else {
            localStorage.removeItem("token");
            navigate("/login/sigma");
          }
        })
        .catch((_) => {
          toasterError(
            "Erreur, impossible de charger les WO, reconnectez-vous à Sigma ou contactez un administrateur."
          );
          localStorage.removeItem("token");
          navigate("/login/sigma");
        });
    }
  }, [user]);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("token");
  };

  const handleSubmit = (values, actions) => {
    // console.log(values.wo);
    SigmaService.getWorkOrder(token, values.wo)
      .then((response) => {
        // console.log(sigmaToForm(response.data));
        navigate("/form", { state: sigmaToForm(response.data) });
      })
      .catch((_) => {
        toasterError(
          "Erreur, impossible de charger la fiche WO, reconnectez-vous à Sigma ou contactez un administrateur."
        );
      });
    actions.setSubmitting(false);
  };

  return (
    <div className={styles.container}>
      <img src="/images/logo.jpg" alt="SDI logo" className={styles.logo} />
      <div className={styles.card}>
        <IconButton
          className={styles.disconnect}
          variant="contained"
          color="error"
          aria-label="disconnect"
          onClick={() => handleLogout()}>
          <LogoutIcon />
        </IconButton>
        <div className={styles.routeur}>
          <img src="/images/routeur.jpg" alt="Routeur" />
        </div>
        <div>
          <h1 className={styles.h1}>Rechercher</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}>
            {({
              values,
              errors,
              isSubmitting,
              isValidating,
              setFieldValue,
            }) => (
              <Form className={styles.Form}>
                <div>
                  <Autocomplete
                    freeSolo
                    autoSelect
                    id="combo-box-demo"
                    options={wos}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="standard"
                        {...params}
                        label="Numéro de mission : WO______"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setFieldValue("wo", newValue);
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={
                    isSubmitting || isValidating || Object.keys(errors).length
                  }
                  color="info">
                  Charger la mission
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <div className={`${styles.mtauto} ${styles.alignselfcenter}`}>
          <Button
            className={`${styles.smallText}`}
            type="submit"
            color="warning"
            variant="text"
            onClick={() => {
              navigate("/form");
            }}>
            Continuer vers un formulaire vide
          </Button>
        </div>
      </div>
      <img
        src="/images/calldispatchlogosdi81.jpg"
        alt="SDI logo"
        className={`${styles.logo} ${styles.calldispatchLogo}`}
      />
    </div>
  );
};

export default SearchPage;
