import { View, Image, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  section: {
    margin: 5,
    padding: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
  logo: {
    height: "50%",
    width: "50%",
  },
  title: {
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
    alignSelf: "center",
  },
  iconInterType: {},
});

const ProcessPageHeader = ({ title, logoPath }) => {
  // console.log("logopath header", logoPath);
  return (
    <>
      <View style={[styles.section, { marginBottom: 0, paddingBottom: 0 }]}>
        <View
          style={[
            styles.row,
            {
              height: "80px",
              paddingVertical: "20px",
              justifyContent: "space-between",
            },
          ]}>
          <View style={styles.row}>
            <Image
              src="./images/logo.jpg"
              style={[styles.logo, { height: "30px", width: "130px" }]}
            />
            <Image
              src="./images/calldispatchlogosdi81.jpg"
              style={styles.logo}
            />
          </View>
          <View
            style={{
              display: "block",
              objectFit: "contain",
              maxWidth: "150px",
              maxHeight: "50px",
              height: "auto",
              width: "auto",
              justifyContent: "center",
            }}>
            <Image src={`./images/${logoPath}`} style={styles.iconInterType} />
          </View>
        </View>
      </View>
      <View style={[styles.section, { marginTop: "0", paddingTop: "0" }]}>
        <Text style={[styles.title]}>{title}</Text>
      </View>
    </>
  );
};

export default ProcessPageHeader;
