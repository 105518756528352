import axios from "axios";

const instance = axios.create({
  baseURL: "https://calldispatch.sdi81.fr:8443/sdiMailer",
  timeout: 5000,
});

axios.defaults.headers.post["Content-Type"] = "application/json";

export default instance;
